.social-link-container {
  display: none;
}

.main-container {
  width: 90%;
  margin: auto;
}

@media only screen and (min-width: 1040px) {
  .social-link-container {
    display: block;
  }

  .main-container {
    margin-left: 8%;
  }
}
