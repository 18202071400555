.hamburger {
  border: 2px $red solid;
  font-size: clamp(1.125rem, 2vw, 1.25rem);
  position: relative;
  z-index: 6;
  display: grid;
  place-items: center;
  outline: 0;
  color: $red;
  background-color: $white;
  height: 35px;
  width: 35px;
}

.hamburger:hover {
  background-color: $red;
  cursor: pointer;
  color: $secondary;
}

.button {
  color: $red;
  border: 2px solid $red;
  font-size: $fs_xs;
  display: block;
  background-color: inherit;
  padding: 10px 18px;
}

.button:hover:enabled {
  color: $white;
  background-color: $red;
  cursor: pointer;
}

.button-icon {
  margin-right: 8px;
}

.button:disabled {
  color: #9f043d;
  border: 2px solid #9f043d;
}

.title-container {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.title {
  display: inline-block;
}

.underline {
  height: 0.2rem;
  width: 40%;
  background-color: $red;
  margin: 0 auto;
}

.footer {
  height: 150px;
  background-color: $secondary;
  display: grid;
  place-items: center;

  &-header {
    margin-bottom: 1em;
  }

  div {
    text-align: center;
  }
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $red;
  color: $secondary;
  text-align: center;
  cursor: pointer;
}

.logo h1 {
  font-size: 44px;
}

@media screen and (min-width: 1040px) {
  .logo {
    width: 55px;
    height: 55px;
  }

  .logo h1 {
    font-size: 62px;
  }
}

@media only screen and (min-width: 768px) {
  .hamburger {
    display: none;
  }

  .button {
    padding: 10px 18px;
    font-size: $fs-s;
  }

  .logo {
    width: 45px;
    height: 45px;
  }

  .logo h1 {
    font-size: 48px;
  }
}
