.contact-me-container {
  margin: auto;
  margin-bottom: 8em;
}

.contact-me-form {
  background-color: $secondary;
  padding: 20px 0 30px 0;
  margin: auto;
}

.input-field {
  display: block;
  margin: 20px auto 0 auto;
  width: 85%;
  padding: 8px 15px;
  box-sizing: border-box;
  font-size: $fs-xs;
  background-color: inherit;
  border: 1px #ccc solid;
}

.input-field:focus {
  outline: none !important;
  border: 1px $red solid;
}

.text-area-field {
  display: block;
  margin: 20px auto 0 auto;
  font-family: inherit;
  width: 85%;
  font-size: $fs-s;
  padding: 8px 15px;
  box-sizing: border-box;
  height: 150px;
  background-color: inherit;
  border: 1px #ccc solid;
}

.text-area-field:focus {
  outline: none !important;
  border: 1px $red solid;
}

.button-container {
  width: 85%;
  margin: 20px auto 0 auto;
}

@media only screen and (min-width: 768px) {
  .contact-me-container {
    width: 90%;
  }
}

@media only screen and (min-width: 1040px) {
  .contact-me-container {
    width: 85%;
  }

  .contactMeForm {
    width: 80%;
  }

  .inputField {
    font-size: $fs-s;
  }

  .text-area-field {
    font-size: $fs-m;
  }
}
