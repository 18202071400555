.my-experience {
  margin: 0 auto 8rem auto;
  text-align: left;
}

.experience-navigation {
  display: flex;
  overflow-x: scroll;
}

.experience-navigation-link {
  margin-right: 1.25em;
  flex-shrink: 0;
  flex-grow: 0;
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  &.active {
    border-bottom: 2px $red solid;
    color: $red;
  }

  &:hover {
    cursor: pointer;
    color: $red;
  }
}

// .experience-navigation-link:hover {
//   cursor: pointer;
//   color: $red;
// }

.job-description {
  display: flex;

  &-container {
    margin-top: 1em;
  }

  &-title {
    letter-spacing: $spacing;
    margin-bottom: 0.2em;
  }

  &-organization {
    background-color: $secondary;
    display: inline-block;
    padding: 0.6em 0.5em 0.3em 0.5em;
    text-transform: uppercase;
    border-radius: 3px;
    color: $red;
    letter-spacing: $spacing;
  }

  &-dates {
    letter-spacing: $spacing;
  }

  &-bullets {
    color: $red;
    margin-right: 0.5em;
  }
}

@media screen and (min-width: 786px) {
  .experience-navigation {
    display: block;
    margin-right: 1em;

    &-link {
      height: 2.5em;
      padding: 0.625em 0 0 1.1em;
      margin-bottom: 0.625em;
    }
  }

  .experience-container {
    display: flex;
  }

  .experience-navigation-link {
    &.active {
      border-bottom: 0;
      border-left: 2px $red solid;
    }
  }

  .job-description-container {
    width: 60%;
    margin-top: 0;
  }
}

@media screen and (min-width: 1040px) {
  .my-experience {
    width: 85%;
  }
}

@media screen and (min-width: 1366px) {
  .experience-navigation {
    margin-right: 3em;
  }
}
