.project-container {
  margin: auto;
  text-align: left;
  margin-bottom: 8rem;
}

.project-descriotion {
  margin: auto;
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.links-container {
  display: flex;
  font-size: clamp(1.25rem, 3vw, 1.5rem);
}

.icon {
  color: inherit;
  margin-left: 1em;
  cursor: pointer;
}

.icon:hover {
  color: var(--red);
}

.tech-container {
  width: 100%;
  margin-top: 1.25em;
  text-align: start;

  span {
    margin-right: 1.25em;
  }
}

.website-layout {
  position: relative;
  margin-bottom: 2em;
  padding-top: 42%;

  &-container {
    width: 100%;
    margin-bottom: 4em;
  }

  .frame-one {
    width: 68%;
    padding-top: 42%;
    bottom: 0px;
    position: absolute;
    display: block;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 100% 100%;
    box-shadow: 2px 3px 5px #ccc;
  }

  .frame-two {
    bottom: 0px;
    width: 40%;
    padding-top: 55%;
    right: -30%;
    position: absolute;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 100% 100%;
    box-shadow: 2px 3px 5px #ccc;
  }

  .frame-three {
    bottom: 0;
    width: 65%;
    padding-top: 120%;
    right: -50%;
    position: absolute;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 100% 100%;
    box-shadow: 2px 3px 5px #ccc;
  }
}

.technology {
  display: inline-block;
  background-color: $secondary;
  padding: 0.4em 0.5em 0.1em 0.5em;
  letter-spacing: $spacing;
  border-radius: 3px;
  color: $red;
  font-weight: bold;
  font-size: clamp(0.8125rem, 2vw, 1rem);
}

.mobile-layout-container {
  width: 100%;
  margin-bottom: 4em;

  .mobile-layout {
    padding-top: 42%;
    position: relative;
    margin-bottom: 3em;

    .frame-one {
      width: 25%;
      padding-top: 42%;
      bottom: 0px;
      position: absolute;
      box-shadow: 2px 3px 5px #ccc;
      background-size: 98% 98%;
    }

    .frame-two {
      bottom: -10px;
      width: 100%;
      height: 100%;
      right: -98%;
      position: absolute;
      box-shadow: 2px 3px 5px #ccc;
      background-size: 100% 100%;
    }
  }
}

@media only screen and (min-width: 1040px) {
  .links-container {
    width: 10%;
  }

  .icon {
    margin-left: 1em;
  }
}

@media only screen and (min-width: 1040px) {
  .project-container {
    width: 85%;
  }
}
